// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { BadgeType } from '@/_typings/enums/badgeType'
import { IColumn } from '@/_typings/interfaces/column'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import showConfirmationModal from '@/utils/showConfirmationModal'
import Badge from '@/components/controls/badge/index.vue'
import GridTable from '@/components/controls/grid-table/index.vue'
import { getQuoteStatusBadgeInfo } from '@/utils/getBadgeInfo'

export default defineComponent({
  name: 'quotes',
  components: {
    Badge,
    GridTable
  },
  BadgeType,
  getQuoteStatusBadgeInfo,
  data() {
    return {
      pagedList: {} as IPagedList,
      selectedItems: [] as any[]
    }
  },
  created() {
    this.getPagedItems({ sortedBy: 'name', sortDirection: 'Ascending' })
  },
  computed: {
    columns() : IColumn[] {
      return [
        { id: 'name', dataType: 'String', label: this.$t('quote_name_label'), name: 'name', isHyperlink: true, customRender: 'name' },
        { id: 'accountName', dataType: 'String', label: this.$t('quote_account_label'), name: 'accountName' },
        { id: 'ownerName', dataType: 'String', label: this.$t('quote_owner_label'), name: 'ownerName' },
        { id: 'status', dataType: 'String', label: this.$t('quote_status_label'), name: 'status', width: '4rem', customRender: 'status' },
        { id: 'extendedCost', dataType: 'Currency', label: this.$t('quote_extended-cost-abbreviated_label'), name: 'extendedCost', width: '7rem' },
        { id: 'extendedSalesPrice', dataType: 'Currency', label: this.$t('quote_extended-sales-price-abbreviated_label'), name: 'extendedSalesPrice', width: '10rem' }
      ] as IColumn[]
    }
  },
  methods: {
    ...mapActions([
      'deleteQuote',
      'deleteQuotes',
      'fetchQuotes'
    ]),
    deleteItem({ id }) {
      showConfirmationModal(confirmationTypes.DELETE, () => {
        this.deleteQuote({ quoteId: id }).then(() => {
          this.getPagedItems({})
          this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== id)
        })
      })
    },
    deleteItems() {
      showConfirmationModal(confirmationTypes.DELETE_MULTIPLE, () => {
        this.deleteQuotes({ quoteIds: this.selectedItems.map(selectedItem => selectedItem.id) }).then(() => {
          this.getPagedItems({})
          this.selectedItems = []
        })
      })
    },
    getPagedItems(pageInfo: any) {
      this.fetchQuotes({ pageInfo: { ...this.pagedList.paging, ...pageInfo } }).then(data => {
        this.pagedList = data
      })
    },
    navigateToQuote(id) {
      this.$router.push({ name: id ? 'quote' : 'quote-new', params: { quoteId: id ? id : null } })
    }
  }
})
