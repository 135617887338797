// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { AlertType } from '@/_typings/enums/alertType'
import { BadgeType } from '@/_typings/enums/badgeType'
import { IAccount } from '@/_typings/interfaces/account'
import { IColumn } from '@/_typings/interfaces/column'
import { IDropdownItem } from '@/_typings/interfaces/dropdown-item'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import { IQuote, IQuoteLine } from '@/_typings/interfaces/quote'
import Badge from '@/components/controls/badge/index.vue'
import ConfiguratorCart from '@/components/misc/configurator-cart/index.vue'
import ConfiguratorCartMixin from '@/components/misc/configurator-cart/configurator-cart-mixin'
import DatePicker from '@/components/controls/date-picker/index.vue'
import Dropdown from '@/components/controls/drop-down/index.vue'
import GridTable from '@/components/controls/grid-table/index.vue'
import NumberInput from '@/components/controls/number-input/index.vue'
import TextInput from '@/components/controls/text-input/index.vue'
import getPageTitle from '@/utils/getPageTitle'
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import showConfirmationModal from '@/utils/showConfirmationModal'
import Localization from '@/utils/localization'
import { getQuoteStatusBadgeInfo } from '@/utils/getBadgeInfo'
import JQuery from 'jquery'

interface IQuoteStatus {
  id: string
  action: string;
  reason: string;
}

export default defineComponent({
  name: 'quote',
  components: {
    Badge,
    ConfiguratorCart,
    DatePicker,
    Dropdown,
    GridTable,
    NumberInput,
    TextInput
  },
  mixins: [ ConfiguratorCartMixin ],
  BadgeType,
  getPageTitle,
  getQuoteStatusBadgeInfo,
  Localization,
  data() {
    return {
      accountList: {} as IPagedList,
      contactList: {} as IPagedList,
      isCartReady: false,
      isSaving: false,
      opportunityList: {} as IPagedList,
      quote: {} as IQuote,
      quoteStatus: {} as IQuoteStatus,
      quoteStatuses: [] as (IDropdownItem & { action: string })[],
      readonly: false,
      selectedLineItems: [] as any[],
      userList: {} as IPagedList,
      validatorKey: 0
    }
  },
  created() {
    if (!!this.$route.params.quoteId) { // view / edit
      this.fetchQuote({ quoteId: this.$route.params.quoteId }).then(data => {
        this.quote = data
      })
      this.readonly = true
    } else { // new
      this.quote = { status: 'New' } as IQuote
      this.fetchLoggedOnUser().then(data => { this.quote.owner = data })
      if (!!this.$route.params.opportunityId) {
        this.fetchOpportunity({ opportunityId: this.$route.params.opportunityId }).then(data => {
          this.quote.opportunity = data
          this.quote.account = data.account
          this.quote.contact = data.contact
        })
      }
    }
    this.fetchAccounts({ pageInfo: { pageSize: 250, sortedBy: 'name', sortDirection: 'Ascending' }, quoteId: this.$route.params.quoteId, activeOnly: true }).then(data => { this.accountList = data })
    this.fetchContacts({ pageInfo: { pageSize: 250, sortedBy: 'fullName', sortDirection: 'Ascending' }, quoteId: this.$route.params.quoteId, activeOnly: true }).then(data => { this.contactList = { ...data, items: data.items.map(item => { return { ...item, name: item.fullName } }) } })
    this.fetchOpportunities({ pageInfo: { pageSize: 250, sortedBy: 'name', sortDirection: 'Ascending' }, quoteId: this.$route.params.quoteId, activeOnly: true }).then(data => { this.opportunityList = data })
    this.fetchUsers({ pageInfo: { pageSize: 250, sortedBy: 'fullName', sortDirection: 'Ascending' } }).then(data => { this.userList = { ...data, items: data.items.map(item => { return { ...item, name: item.fullName } }) } })
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    isLocked(): boolean {
      return this.quote.status === 'Active' || this.quote.status === 'Revised' || this.quote.status === 'Ordered' || this.quote.status === 'Closed'
    },
    lineColumns() : IColumn[] {
      return [
        { id: 'sku', dataType: 'String', label: this.$t('quote-line_sku_column-header'), name: 'sku', isHyperlink: true },
        { id: 'name', dataType: 'String', label: this.$t('quote-line_name_column-header'), name: 'name' },
        { id: 'quantity', dataType: 'Numeric', label: this.$t('quote-line_quantity_column-header'), name: 'quantity', width: '10rem' },
        { id: 'unitCost', dataType: 'Currency', label: this.$t('quote-line_unit-cost_column-header'), name: 'unitCost', width: '10rem' },
        { id: 'unitPrice', dataType: 'Currency', label: this.$t('quote-line_unit-price_column-header'), name: 'unitPrice', width: '10rem' },
        { id: 'extendedSalesPrice', dataType: 'Currency', label: this.$t('quote-line_extended-sales-price-abbreviated_label'), name: 'extendedSalesPrice', width: '10rem' }
      ] as IColumn[]
    }
  },
  methods: {
    ...mapMutations([
      'setAlert',
      'setBreadcrumbText'
    ]),
    ...mapActions([
      'addQuote',
      'copyQuote',
      'deleteQuoteLine',
      'deleteQuoteLines',
      'fetchAccounts',
      'fetchContacts',
      'fetchLoggedOnUser',
      'fetchOpportunities',
      'fetchOpportunity',
      'fetchQuote',
      'fetchQuoteStatuses',
      'fetchQuoteLines',
      'fetchUsers',
      'generateDocument',
      'reviseQuote',
      'updateQuote',
      'updateQuoteStatus'
    ]),
    changeAccount(account: IAccount) {
      this.quote.account = account
      this.fetchContacts({ pageInfo: { pageSize: 250, sortedBy: 'fullName', sortDirection: 'Ascending' }, quoteId: this.$route.params.quoteId, accountId: account.id, activeOnly: true }).then(data => { this.contactList = { ...data, items: data.items.map(item => { return { ...item, name: item.fullName } }) } })
    },
    copyQuotation() {
      this.copyQuote({ quoteId: this.quote.id }).then((data) => {
        this.setAlert({ type: AlertType.Success, title: this.$t('reseller_quote-copy-1_message'), message: this.$t('reseller_quote-copy-2_message'), timeout: 3000 })
        this.$router.push({ path: '/refresh', query: this.$route.params.opportunityId ? { name: 'opportunity', id: this.$route.params.opportunityId, name2: 'quote', id2: data.id } : { name: 'quote', id: data.id } })
      })
    },
    deleteLineItem({ id }) {
      showConfirmationModal(confirmationTypes.DELETE, () => {
        this.deleteQuoteLine({ quoteId: this.quote.id, lineId: id }).then((data) => {
          this.quote = data
          this.selectedLineItems = this.selectedLineItems.filter(selectedItem => selectedItem.id !== id)
          this.refreshCart()
        })
      })
    },
    deleteLineItems() {
      showConfirmationModal(confirmationTypes.DELETE_MULTIPLE, () => {
        this.deleteQuoteLines({ quoteId: this.quote.id, lineIds: this.selectedLineItems.map(selectedItem => selectedItem.id) }).then((data) => {
          this.quote = data
          this.selectedLineItems = []
          this.refreshCart()
        })
      })
    },
    getPagedLineItems(pageInfo: any) {
      this.fetchQuoteLines({ quoteId: this.quote.id, pageInfo: { ...this.quote.lines.paging, ...pageInfo } }).then(data => {
        this.quote.lines = data
      })
    },
    navigateToConfigurator(e: any) {
      if (e.canConfigure === false) return
      this.$router.push({
        name: !!this.$route.params.opportunityId ? 'opportunity-quote-configurator' : 'quote-configurator',
        params: { quoteId: this.quote.id, opportunityId: this.$route.params.opportunityId || null },
        query: { dealId: this.quote.omniDealId, cartId: this.quote.omniCartId, rootConfigurationId: e.omniRootConfigurationItemId || e.rootConfigurationId, configurationId: e.omniConfigurationItemId || e.configurationId }
      })
    },
    navigateToProductCatalog() {
      this.$router.push({
        name: !!this.$route.params.opportunityId ? 'opportunity-quote-product-catalog' : 'quote-product-catalog',
        params: { quoteId: this.quote.id, opportunityId: this.$route.params.opportunityId || null }
      })
    },
    refreshCart() {
      this.$el.querySelector('.configurator-cart iframe')?.contentWindow.postMessage({ type: 'REFRESH-SESSION', reconfigureCart: true }, '*')  
    },
    refreshQuote() {
      this.fetchQuote({ quoteId: this.$route.params.quoteId }).then(data => {
        this.quote = data
      })
    },
    reviseQuotation() {
      this.reviseQuote({ quoteId: this.quote.id }).then((data) => {
        this.setAlert({ type: AlertType.Success, title: this.$t('reseller_quote-revise-1_message'), message: this.$t('reseller_quote-revise-2_message'), timeout: 3000 })
        this.$router.push({ path: '/refresh', query: this.$route.params.opportunityId ? { name: 'opportunity', id: this.$route.params.opportunityId, name2: 'quote', id2: data.id } : { name: 'quote', id: data.id } })
      })
    },
    saveQuote() {
      this.validatorKey++
      this.$nextTick(() => {
        if (this.$el.querySelectorAll('#quote .form-input .invalid').length) return
        this.isSaving = true
        const action = this.quote.id ? this.updateQuote : this.addQuote
        action({ quote: this.quote })
          .then((data) => {
            if (this.quote.id) {
              this.quote = data
              this.setBreadcrumbText({ id: this.quote.id, text: this.quote.name })
              this.readonly = true
            } else {
              this.$router.push({ path: '/refresh', query: this.$route.params.opportunityId ? { name: 'opportunity', id: this.$route.params.opportunityId, name2: 'quote', id2: data.id } : { name: 'quote', id: data.id } })
            }
            this.setAlert({ type: AlertType.Success, title: this.$t('reseller_quote-save-1_message'), message: this.$t('reseller_quote-save-2_message'), timeout: 3000 })
          })
          .finally(() => {
            this.isSaving = false
          })
      })
    },
    saveQuoteStatus() {
      this.updateQuoteStatus({ quoteId: this.quote.id, quoteStatus: this.quoteStatus }).then((data) => {
        this.quote = data
        if (this.isLocked) this.readonly = true
        JQuery('#quote-status').modal('hide')
        this.refreshCart()
      })
    },
    async showQuoteStatusModal() {
      const data = await this.fetchQuoteStatuses({ quoteId: this.quote.id })
      this.quoteStatuses = data.map(item => { return { id: item.status, name: this.$t('quote_status-' + item.status.toLowerCase() + '_label'), action: item.actionName } })
      this.quoteStatus = {} as IQuoteStatus
      JQuery('#quote-status').modal()
    },
    submitDocumentGeneration() {
      this.generateDocument({ quoteId: this.quote.id })
      this.setAlert({ type: AlertType.Info, message: this.$t('reseller_document-generation-submitted_message'), timeout: 3000 })
    }
  }
})
